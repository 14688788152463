<script>
export default {
  data() {
    return {
      emp_file_types: [],
      EmpFileTypes: {},
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchEmpFileTypes: null,
      EmpFileTypesSearchMode: false,
      // appprices:[]
    };
  },
  methods: {
    addEmpFileTypes() {
      var data = {
        title: "popups.addempfiletype",
        inputs: [
          { model: "name", type: "text", label: "popups.name" },

          { model: "number", type: "number", label: "popups.number" },
        ],
        buttons: [
          {
            text: "popups.add",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.post("emp-file-types", obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    editEmpFileTypes(app) {
      var data = {
        title: "popups.editempfiletype",
        inputs: [
          {
            model: "name",
            type: "text",
            label: "popups.name",
            value: app.name,
          },

          {
            model: "number",
            type: "text",
            label: "popups.number",
            value: app.number,
          },
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.put("emp-file-types", app.id, obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    search() {
      this.EmpFileTypesSearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("emp-file-types/search", {
          search: this.searchModel,
          limit: this.limit,
          page: this.page,
          col: "name",
        })
        .then((res) => {
          this.emp_file_types = res.data;
        });
    },
    cancelappsearchMode() {
      this.searchModel = "";
      this.EmpFileTypesSearchMode = false;
      this.get(this.page);
    },
    getEmpFileTypes() {
      this.http.get("emp-file-types").then((res) => {
        this.emp_file_types = res.data;
      });
    },
    deleteEmpFileTypes(app) {
      var data = {
        title: "popups.deleteemp",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          this.http.delete("emp-file-types", app.id).then(() => {
            this.get(this.page);
          });
        }
      });
    },

    get(page) {
      console.log(page);
      this.http
        .post("emp-file-types/pagination", {
          limit: this.limit,
          page: page,
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.emp_file_types = res.data;
        });
    },
  },
  created() {
    this.get(1);
    this.getEmpFileTypes();
  },
};
</script>

<template>
  <div class="row">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-6">
            <div class="search-box chat-search-box w-50">
              <div class="position-relative">
                <input
                  @keyup="search()"
                  type="text"
                  class="form-control fa-lg text-light"
                  :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                  :placeholder="$t('popups.search')"
                  v-model="searchModel"
                  style="background-color: #2a3042 !important"
                />
                <i class="bx bx-search-alt search-icon text-light"></i>
              </div>
            </div>
          </div>
          <div class="col-6" style="display: flex; justify-content: flex-end">
            <button
              class="btn-close text-"
              v-if="EmpFileTypesSearchMode"
              @click="cancelappsearchMode()"
            ></button>
            <button
              type="button"
              @click="addEmpFileTypes()"
              class="btn btn-light float-end mb-4"
            >
              <span
                class="bx bxs-plus-circle float-end fa-2x"
                style="color: #2a3042 !important"
              ></span>
            </button>
          </div>
        </div>

        <table
          :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
          class="table table-striped table-hover mb-0"
        >
          <thead>
            <tr class="text-light" style="background-color: #2a3042 !important">
              <th scope="col">#</th>
              <th scope="col">{{ $t("emp_file_types.name") }}</th>
              <th scope="col">{{ $t("emp_file_types.number") }}</th>
              <th scope="col">{{ $t("emp_file_types.created") }}</th>
              <th scope="col">{{ $t("emp_file_types.updated") }}</th>
              <th scope="col">{{ $t("emp_file_types.operations") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(app, index) in emp_file_types" :key="app" class="">
              <td>{{ index + 1 }}</td>
              <td>{{ app.name }}</td>
              <td>{{ app.number }}</td>
              <td>{{ app.created.split("T")[0] }}</td>
              <td>{{ app.updated.split("T")[0] }}</td>
              <td>
                <a
                  class="btn btn-primary me-2"
                  @click="editEmpFileTypes(app)"
                  href="javascript: void(0);"
                  role="button"
                  >{{ $t("popups.edit") }}</a
                >
                <a
                  class="btn btn-danger me-2"
                  href="javascript: void(0);"
                  role="button"
                  @click="deleteEmpFileTypes(app)"
                  >{{ $t("popups.delete") }}</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <!-- end  table -->
  <!-- Button trigger modal -->

  <!--   Apps  Pagination     -->
  <div>
    <ul class="pagination pagination-rounded justify-content-center mb-2">
      <li class="page-item" :class="{ disabled: page == 1 }">
        <a
          class="page-link"
          href="javascript: void(0);"
          @click="get(page - 1)"
          aria-label="Previous"
        >
          <i class="mdi mdi-chevron-left"></i>
        </a>
      </li>
      <li
        class="page-item"
        :class="{ active: p == page }"
        v-for="p in tot_pages"
        :key="p"
      >
        <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
          p
        }}</a>
      </li>

      <li class="page-item" :class="{ disabled: page == total_pages }">
        <a
          class="page-link"
          href="javascript: void(0);"
          @click="get(page + 1)"
          aria-label="Next"
        >
          <i class="mdi mdi-chevron-right"></i>
        </a>
      </li>
    </ul>
  </div>
  <!--end-->
</template>

<style scoped>
.btn-close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}
</style>
