<script>
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import DocumentTypes from "./DocumentTypes/index.vue";
import EmpsDepartments from "./Departments/index.vue";
import EmpsDesignations from "./Designations/index.vue";
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    EmpsDepartments,
    EmpsDesignations,
    DocumentTypes,
  },
  data() {
    return {};
  },
  methods: {},
  created() {},
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="$t('menu.menuitems.hr.subItems.masters')"
    />
    <b-tabs
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      justified
      nav-class="nav-tabs-custom"
      content-class="p-3 text-muted"
    >
      <!-- start departments -->
      <b-tab active>
        <template v-slot:title>
          <span
            :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            class="d-inline-block d-sm-none"
          >
            <i class="fas fa-home"></i>
          </span>
          <span class="d-none d-sm-inline-block">{{
            $t("masters.tabs.departments")
          }}</span>
        </template>
        <EmpsDepartments />
      </b-tab>
      <!-- end departments -->

      <!-- start designations -->
      <b-tab>
        <template v-slot:title>
          <span
            :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            class="d-inline-block d-sm-none"
          >
            <i class="fas fa-home"></i>
          </span>
          <span class="d-none d-sm-inline-block">{{
            $t("masters.tabs.designations")
          }}</span>
        </template>
        <EmpsDesignations />
      </b-tab>
      <!-- end designations -->

      <!-- start file types -->
      <b-tab>
        <template v-slot:title>
          <span
            :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            class="d-inline-block d-sm-none"
          >
            <i class="fas fa-home"></i>
          </span>
          <span class="d-none d-sm-inline-block">{{
            $t("masters.tabs.doc-types")
          }}</span>
        </template>
        <DocumentTypes />>
      </b-tab>
      <!-- end file types -->
    </b-tabs>
  </Layout>
</template>
<style scoped></style>
